.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    text-shadow: 0 0 10px rgba(62, 140, 160, 0.5);
    box-shadow: 0 0 10px rgba(62, 140, 160, 0.8);
}

.loading-border {
    border: 2px solid #969ea1;
    padding: 20px;
    text-align: center;
}

.loading-text {
    color: #969ea1;
    margin-bottom: 20px;
}

.loading-bar {
    width: 300px;
    height: 20px;
    background-color: #3e494b;
    overflow: hidden;
}

.loading-progress {
    height: 100%;
    width: 0;
    background-color: #969ea1;
    transition: width 2s linear;
    animation: loadProgress 2s linear forwards;
}

@keyframes loadProgress {
    to { width: 100%; }
}