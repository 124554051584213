.desktop {
  background-color: #008080;
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
}

.icon-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  text-align: center;
  width: 50px;
}

.taskbar {
  background-color: #c0c0c0;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px;
  box-shadow: 2px 2px 0 #000;
}

.start-button {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 10px;
  font-family: 'MS Sans Serif', Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.taskbar-windows {
  display: flex;
  margin-left: 20px;
}

.start-menu {
  background-color: #c0c0c0;
  border: 2px outset #fff;
  position: fixed;
  bottom: 35px;
  left: 0;
  width: 200px;
  padding: 5px;
  box-shadow: 2px 2px 0 #000;
  z-index: 2;
}

.menu-item {
  display: block;
  background-color: #c0c0c0;
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  font-family: 'MS Sans Serif', Arial, sans-serif;
  font-size: 14px;
  border-bottom: 1px solid #fff;
}

.menu-item:hover {
  background-color: #a0a0a0;
}

.project-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #c0c0c0;
  border: 2px solid #000;
  z-index: 3;
  padding: 10px;
  box-shadow: 2px 2px 0 #000;
  width: 300px;
}

.project-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.project-modal-body {
  font-family: 'MS Sans Serif', Arial, sans-serif;
}
