.about, #debug {
    font-family: 'Times New Roman', Times, serif;
    text-shadow: none;
}

.about {
    background-color: black;
    background-image: url(../../assets/background/dungeon.jpg);
    background-repeat: repeat;
    background-size: 200px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.about a {
    color: #993399;
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif;
}

.about a, .about p {
    font-size: 1.2em;
}

.about h1, .about p {
    color: #993399;
    margin-bottom: 0;
}

.about p {
    color: #fff; 
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
}

.aboutme-logo {
    margin-top: 10px;
    width: 40%;
}

.aboutme-links a {
    margin: 0 5px;
    font-family: 'Times New Roman', Times, serif;
}

.aboutme-links a:hover {
    text-decoration: underline;
}

.aboutme-separator {
    margin: 10px auto 0 auto;
    width: 30%;
}

.layout-table {
    width: 100%;
    height: 100vh;
}

.side-column, .middle-column {
    vertical-align: middle;
}

.side-column {
    width: 20%;
}

.side-column .wiz {
    width: 400px;
    height: auto;
    margin-left: 30px;
}

.side-column .crystal {
    width: 200px;
    height: auto;
}

.middle-column {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.image-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
}

.header-with-torches {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.torch-l .torch-r {
    margin: 0 10px;
}

.torch-r {
    transform: scaleX(-1);
}

.section {
    margin-left: auto;
    margin-right: auto;
}

.section h1, .section img {
    display: block;
}

.section h1 {
    margin: 0;
}

.aboutme-footer {
    text-align: center;
    color: #fff;
    align-items: center;
    margin-bottom: 10px;
}

.ad {
    margin: 20px 0;
}

