
@font-face {
    font-family: russiangothic;
    src: local("Arial");
    font-weight: 400;
    font-style: normal;
    unicode-range: U+0420-42FF
}

.main {
  overflow-x: hidden;
  margin: 0;
  background-image: url(../../assets/background/pixel-dark.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #101010;
}

.main, #debug {
  image-rendering: pixelated;
  font-family: russiangothic, ms ui gothic, Tahoma;
  text-shadow: var(--body-text-shadow);
}

:root {
  --purple-text-shadow: 0 0 20px #660000;
  --body-text-shadow: 0 0 20px #ffffff4a, 0 0 20px #00ffab45;
}

.main table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
}

.main tbody {
  display: table-row-group;
  border-color: inherit
}

.main td {
  display: table-cell;
}


.main #logo {
  text-align: center;
  padding-top: 5px;
  margin-right: 50px;
  font-size: 2em;
}

.main h1 {
  color: #660033;
  font-family: pixel-nes;
  margin: 0;
  text-shadow: var(--purple-text-shadow);
}

.main h2 {
  color: #660033;
  font-family: pixel-nes;
  margin: 0;
  text-shadow: var(--purple-text-shadow);
}

.main a {
  color: #660033;
  text-decoration: none;
  text-shadow: var(--purple-text-shadow);
}

.main a:hover {
  text-decoration: underline;
}

.main .logo {
  text-align: center;
  padding-top: 5px;
}

.main .maintd {
  width: 675px;
  vertical-align: baseline;
}

.main .menutd {
  width: 225px;
  vertical-align: baseline;
}

.main .box {
  background-color: #000000a8;
  border: 2px solid #383838;
  border-radius: 4px;
  color: #fff;
  padding: 10px 15px;
  margin: 5px;
}

.main .textarea {
  background-color: #000;
  color: #fff;
  border: 1px solid #383838;
  font-family: MS Gothic,monospace;
  resize: none;
}

.main #bg {
  z-index: -999;
  background-size: cover;
  image-rendering: pixelated;
  background-color: #000;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.main #container, #backside-container {
    position: relative;
    z-index: 1;
    width: 900px;
    margin: 0 auto;
}

.main .navlink {
  color: #fff;
  display: inline-block;
  margin-bottom: 3px;
}

@media screen and (min-width: 601px) {
   body:before {
    content: "";
    position: fixed;
    opacity: .2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;
   }

   body:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    opacity: .5;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;
   }
  
}

.main .marquee {
    width: 100%;
    line-height: 10px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}

.main .marquee p {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 30s linear infinite;
}

@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

.main footer {
    text-align: center;
    color: #fff;
    font-size: 0.8em;
    margin-top: 20px;
}