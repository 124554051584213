
.persona {
    font-family: VT323, monospace;
    color: #c8cece;
    width: 100%;
    height: 100%;
}

.title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #000;
    color: #fff;
    padding: 5px;
}

.title-bar span {
    font-weight: bold;
    font-size: 30px;
}

.signal {
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 20px;
}

.battery-icon {
    margin-left: 10px;
    margin-right: 5px;
}

.profile-section {
    display: flex;
    flex-direction: row;
    background: #000;
    width: 100%;
}

.profile-pic {
    width: 100%;
    height: 350px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: #3e494b 2px solid;
}

.profile-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background-color: #121d1f;
}

.profile-info-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#title {
    font-size: 30px;
    text-align: left;
}

#myname {
    font-size: 40px;
    text-align: left;
}

.profile-info-right {
    display: flex;
    flex-direction: column;
    text-align: right;
    color: #404b4f;
}

#access {
    font-size: 30px;
}

#asterisk {
    font-size: 30px;
    margin-top: 0;
}


.facts-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
}

.facts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    font-size: 30px;
}

.facts2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    font-size: 35px;
}

.facts2 img {
    width: 70%;
}

.bio-section {
    background: #000;
    padding: 10px;
    font-size: 24px;
}

.title-bar,
.profile-info,
.profile-pic,
.facts-stats,
.bio-section {
    border: 2px solid #3e494b;
    padding: 8px;
}

.property-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #3e494b;
    color: #404b4f;
    font-size: 30px;
    height: 100%;
    align-items: center;
}

.property-section img {
    width: 100px;
    height: 80px;
}

.property-text {
    text-align: center;
    display: flex;
    flex-direction: column;
}