.resume {
  display: flex;
  background-color: #000;
  min-height: 100vh;
  width: 100vw;
  font-family: vt323, monospace;
  text-shadow: 0 0 10px rgba(62, 140, 160, 0.5);
}

.outer {
  border: 2px solid #3e494b;
  background-color: #000;
  display: flex;
  flex-direction: row;
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100vh - 40px);
  overflow: hidden;
}

.left-menu, .persona {
  border: 2px solid #3e494b;
  display: flex;
  flex-direction: column;
}

.left-menu {
  border-right: 4px solid #3e494b;
  flex: 1;
  align-items: center;
}

.persona {
  margin-left: -1px;
  margin-top: -2px;
  margin-bottom: -2px;
  flex: 3;
}

.terminal-container {
  flex: 6;
  display: flex;
  flex-direction: column;
  border: 2px solid #3e494b;
  margin-left: -1px;
  margin-right: -1px;
}

.terminal {
  align-items: center;
  text-align: left;
  color: #969ea1;
  font-size: 20px;
  padding: 20px;
  white-space: pre-wrap;
  overflow-y: auto;
  flex-grow: 1;
}

.navigation, .nav-button {
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
  width: 100%;
  margin: 8px;
}

.nav-button {
  background-color: #121d1f;
  color: #969ea1;
  text-decoration: none;
  padding: 10px 15px;
  display: inline-block;
  border: none;
  cursor: pointer;
  width: 100px;
  height: 50px;
  line-height: 50px;
}

.nav-button:hover {
  background-color: #3e494b;
}

.scanlines {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(
    transparent,
    transparent 1px,
    rgba(0, 0, 0, 0.1) 4px,
    rgba(0, 0, 0, 0.1) 8px
  );
  pointer-events: none;
  z-index: 1;
}
